import React, {
  forwardRef,
  useRef,
  createRef,
  useState,
  Children,
  cloneElement,
  useImperativeHandle,
} from "react";
import "./FormComponent.scss";
import TextFieldComponent from "../TextField/TextFieldComponent";
import GroupTextFieldComponent from "./GroupTextFieldComponent";
import ButtonComponent_v2 from "../button/ButtonComponent_v2";

//children can accept element other than textfieldComponent
//for textfield component must have props called isFormChild, it will only do the logic automatically.
//if the textfield is in nested level of a div or any element, need to put props data-includedformchild
//only it can detect the nested level textfield, example below:
// <div data-includedformchild={true}>
//  <div>
//    <div>blocker</div>
//    <div>test</div>
//  </div>
//  <div>
//     <div>testing purses</div>
//     <LoginCommonTf
//              isFormChild={true}
//              icon={tfImg.user}
//              name={nameTfName}
//              placeholder={trans('general.placeholder.enterName')}
//              value={formData[nameTfName] || ''}
//             onChange={handleFieldChange}
//             isRequired={true}
//       />
//   </div>
//  </div>
//textfield component if not wrapped inside formcomponent might have forwardRef error,
//can solved by do null checking for the ref in the component.
//or assign a ref to it

//frameRef: if you pass in some div ref to frameRef, the scrollTo function will use the div top position as top edge. Default it will use the formcomponent top position as the top edge
const FormComponent = forwardRef(
  (
    {
      children,
      scrollRef,
      frameRef,
      submitBtnRef, //'enter' key will trigger this btn
      ...props
    },
    ref
  ) => {
    const textFields = useRef([]);
    const containerRef = useRef();

    //can be use after executed validatevalue function
    //for scenario that want to check whether form tf all valid without re-run the validateValue function
    const isValidRef = useRef(false);

    React.useImperativeHandle(ref, () => ({
      ValidateValue,
      textFields,
      //in order to use this, need to pass in name props to tf component
      getTextFieldRefByName: (name) => {
        const targetRef = textFields.current.find((obj) => obj.name === name);
        console.log(`check ref `, textFields.current);
        return targetRef?.ref ?? null;
      },
      getBoundingClientRect: () => {
        return containerRef.current.getBoundingClientRect();
      },
      isValid: isValidRef.current,
      onSubmit: () => {
        const btnRefValue = submitBtnRef?.current;
        console.log(btnRefValue);
        if (btnRefValue && btnRefValue.click) {
          btnRefValue.click();
        }
      },
    }));

    const ValidateValue = async ({ scrollTo = true } = {}) => {
      let validationFailed = false;
      // Iterate through all text fields and trigger their validation
      const validations = [];
      for (const index in textFields.current) {
        const textFieldRef = textFields.current[index].ref;
        const { disabledFromFormValidation } = textFieldRef.current.props || {};
        const isManualSetErr = textFieldRef.current?.isManualSetErr || false;
        const currentErrMsg = textFieldRef.current?.errMsg || "";
        //if manualseterr and currenterrmsg not '' or null only skip validateValue
        const isManualErr = isManualSetErr && currentErrMsg;
        if (textFieldRef.current?.ValidateValue) {
          validations.push({
            //store .current instead of textFieldRef, because if setstate did inside validation will having effect
            textFieldRef: textFieldRef.current,
            validation: isManualErr
              ? false
              : textFieldRef.current.ValidateValue(
                  disabledFromFormValidation
                    ? disabledFromFormValidation
                    : false
                ),
          });
        }
      }
      await Promise.all(validations.map((item) => item.validation)).then(
        (results) => {
          const index = results.indexOf(false);
          validationFailed = index > -1 ? true : false;
          if (scrollTo && index > -1) {
            const targetRef = validations[index].textFieldRef;
            if (targetRef.ScrollToTextField) {
              targetRef.ScrollToTextField();
            }
          }
        }
      );
      isValidRef.current = !validationFailed;
      return !validationFailed;
    };

    // Add a ref to each child (text field) with additional props
    const childrenWithProps = (
      childrenElement,
      index,
      isIncludedFormChild = false
    ) => {
      const finalChildren = Children.map(childrenElement, (child) => {
        if (!React.isValidElement(child)) {
          return child;
        }

        if (
          (child.props["data-includedformchild"] || isIncludedFormChild) &&
          !child.props.isFormChild &&
          child.props.children
        ) {
          const result = childrenWithProps(child.props.children, index, true);
          index = result.index;
          return cloneElement(child, {
            children: result.element,
          });
        }

        let clonedChild;
        if (child.props.isFormChild) {
          const textFieldRef = createRef();
          if (child.props.name) {
            textFields.current[index] = {
              name: child.props.name,
              ref: textFieldRef,
            };
          } else {
            textFields.current[index] = { name: index, ref: textFieldRef };
          }
          clonedChild = cloneElement(child, {
            ref: textFieldRef,
            formRef: ref,
            scrollRef: scrollRef,
            frameRef: frameRef,
          });

          index++;
        } else {
          clonedChild = child;
        }

        return clonedChild;
      });

      return { element: finalChildren, index: index };
    };

    return (
      <div {...props} ref={containerRef}>
        {childrenWithProps(children, 0).element}
      </div>
    );
  }
);

export default FormComponent;

// EXAMPLE IMPLEMENTATION:
// export function EXAMPLE({frameRef, children}) {
//   const [accName, setAccName] = useState('');
//   const [accNum, setAccNum] = useState('');
//   const [amount, setAmount] = useState('');
//   const [accName1, setAccName1] = useState('');
//   const [accNum1, setAccNum1] = useState('');
//   const [amount1, setAmount1] = useState('');
//   const [accName2, setAccName2] = useState('');
//   const [accNum2, setAccNum2] = useState('');
//   const [amount2, setAmount2] = useState('');
//   const [accName3, setAccName3] = useState('');
//   const [accNum3, setAccNum3] = useState('');
//   const [amount3, setAmount3] = useState('');
//   const formRef = useRef(null);
//   const containerRef = useRef(null);

//   const handleButtonClick = async () => {
//     // Trigger the handleValidation function in the FormComponent
//     const isValid = await formRef.current.ValidateValue({});
//     console.log(frameRef);
//     // You can perform additional logic based on the validation result
//     if (isValid) {
//       console.log(`success`);
//       // Perform actions when validation succeeds
//     } else {
//       console.log(`failed`);
//       // Perform actions when validation fails
//     }
//   };

//   return (
//     <div className="trueWallet-container" ref={containerRef}>
//       <FormComponent ref={formRef} frameRef={frameRef}>
//         <ButtonComponent_v2
//           text={'Submit'}
//           btnClassName={'trueWallet-submit-btn'}
//           btnTextClassName={'trueWallet-submit-btn-txt'}
//           onClick={() => {
//             handleButtonClick();
//           }}
//         />
//         <GroupTextFieldComponent>
//           <TextFieldComponent
//             value={accName}
//             title={'Account Name'}
//             placeholder={'Type account name'}
//             isRequired={true}
//             onChange={(e) => {
//               setAccName(e.target.value);
//             }}
//             validation={(value) => {
//               if (value != accNum) {
//                 return 'Not match';
//               } else {
//                 return '';
//               }
//             }}
//           />
//           <div>BLOCKEr</div>
//           <TextFieldComponent
//             value={accNum}
//             title={'Account Number'}
//             placeholder={'Type account number'}
//             isRequired={true}
//             onChange={(e) => {
//               setAccNum(e.target.value);
//             }}
//           />
//         </GroupTextFieldComponent>
//         <div>HAHAHAH i also donno</div>
//         <TextFieldComponent
//           value={amount}
//           title={'Amount'}
//           placeholder={'Type amount'}
//           inputMode="numeric"
//           onChange={(e) => {
//             setAmount(e.target.value);
//           }}
//           //   ref={amountRef}
//           isRequired={true}
//           maxDecimalPlaces={2}
//           validation={(value) => {
//             const floatValue = parseFloat(value);
//             if (floatValue < 10) {
//               //   amountRef.current.setErrMsg(`please above 10`);
//               return `please above 10`;
//             }

//             if (floatValue > 5000) {
//               //   amountRef.current.setErrMsg(`please below 5000`);
//               return `please below 5000`;
//             }

//             return '';
//           }}
//         />
//         <TextFieldComponent
//           value={accName1}
//           title={'Account Name'}
//           placeholder={'Type account name'}
//           isRequired={true}
//           onChange={(e) => {
//             setAccName1(e.target.value);
//           }}
//         />
//         <TextFieldComponent
//           value={accNum1}
//           title={'Account Number'}
//           placeholder={'Type account number'}
//           isRequired={true}
//           onChange={(e) => {
//             setAccNum1(e.target.value);
//           }}
//         />
//         <TextFieldComponent
//           value={amount1}
//           title={'Amount'}
//           placeholder={'Type amount'}
//           inputMode="numeric"
//           onChange={(e) => {
//             setAmount1(e.target.value);
//           }}
//           //   ref={amountRef}
//           isRequired={true}
//           maxDecimalPlaces={2}
//           validation={(value) => {
//             const floatValue = parseFloat(value);
//             if (floatValue < 10) {
//               //   amountRef.current.setErrMsg(`please above 10`);
//               return `please above 10`;
//             }

//             if (floatValue > 5000) {
//               //   amountRef.current.setErrMsg(`please below 5000`);
//               return `please below 5000`;
//             }

//             return '';
//           }}
//         />
//         <TextFieldComponent
//           value={accName2}
//           title={'Account Name'}
//           placeholder={'Type account name'}
//           isRequired={true}
//           onChange={(e) => {
//             setAccName2(e.target.value);
//           }}
//         />
//         <TextFieldComponent
//           value={accNum2}
//           title={'Account Number'}
//           placeholder={'Type account number'}
//           isRequired={true}
//           onChange={(e) => {
//             setAccNum2(e.target.value);
//           }}
//         />
//         <TextFieldComponent
//           value={amount2}
//           title={'Amount'}
//           placeholder={'Type amount'}
//           inputMode="numeric"
//           onChange={(e) => {
//             setAmount2(e.target.value);
//           }}
//           //   ref={amountRef}
//           isRequired={true}
//           maxDecimalPlaces={2}
//           validation={(value) => {
//             const floatValue = parseFloat(value);
//             if (floatValue < 10) {
//               //   amountRef.current.setErrMsg(`please above 10`);
//               return `please above 10`;
//             }

//             if (floatValue > 5000) {
//               //   amountRef.current.setErrMsg(`please below 5000`);
//               return `please below 5000`;
//             }

//             return '';
//           }}
//         />
//         <TextFieldComponent
//           value={accName3}
//           title={'Account Name'}
//           placeholder={'Type account name'}
//           isRequired={true}
//           onChange={(e) => {
//             setAccName3(e.target.value);
//           }}
//         />
//         <TextFieldComponent
//           value={accNum3}
//           title={'Account Number'}
//           placeholder={'Type account number'}
//           isRequired={true}
//           onChange={(e) => {
//             setAccNum3(e.target.value);
//           }}
//         />
//         <TextFieldComponent
//           value={amount3}
//           title={'Amount'}
//           placeholder={'Type amount'}
//           inputMode="numeric"
//           onChange={(e) => {
//             setAmount3(e.target.value);
//           }}
//           //   ref={amountRef}
//           isRequired={true}
//           maxDecimalPlaces={2}
//           validation={(value) => {
//             const floatValue = parseFloat(value);
//             if (floatValue < 10) {
//               //   amountRef.current.setErrMsg(`please above 10`);
//               return `please above 10`;
//             }

//             if (floatValue > 5000) {
//               //   amountRef.current.setErrMsg(`please below 5000`);
//               return `please below 5000`;
//             }

//             return '';
//           }}
//         />
//       </FormComponent>

//       <ButtonComponent_v2
//         text={'Submit'}
//         btnClassName={'trueWallet-submit-btn'}
//         btnTextClassName={'trueWallet-submit-btn-txt'}
//         onClick={() => {
//           handleButtonClick();
//         }}
//       />
//     </div>
//   );
// }
