import React, { useEffect, useLayoutEffect, useState } from "react";
import "./FundCardComponent.scss";
import { GetCommon } from "../../../resources/image/Image";
import scssVariables from "../../../resources/theme/Common.scss";
import ButtonComponent_v2 from "../button/ButtonComponent_v2";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import api from "../../../common/api";
import CPButton from "../../../common/components/button/CPButton";
import CPProgressBar from "../../../common/components/progressBar/CPProgressBar";
import Dialog from "../../../common/components/root/dialog/Dialog";
import Info from "../../../common/components/root/info/Info";
import Timer from "../../../common/components/timer/Timer";
import { format } from "../../../common/utils";
import { GetFund } from "../../../resources/image/Image";
import TopNav from "../../../pages/modal/TopNav";
import { useNav as useNavigate } from "../../../common/utils/helper";
import { GetWallet } from "../../../resources/image/Image";

export default function FundCardComponent({
  containerClassName,
  btnTopupClassName,
  btnWithdrawClassName,
  topupCallback,
  withdrawCallback,
  iconImg,
  nameClassName,
  balTitleClassName,
  balAmountClassName,
  withdrawBalClassName,
}) {
  const Common = GetCommon();
  const { t: trans } = useTranslation();

  const icon = GetFund();
  let location = useLocation();
  const { back } = location.state ?? {};
  const navigate = useNavigate();
  const { wallet, wdrlBal, isMobile } = useSelector(({ user }) => user);
  const { currency } = useSelector(({ company }) => company);

  const [timer, setTimer] = useState(0);
  const [item, setItem] = useState({});
  const [bal, setBal] = useState(wallet.currbal);
  const [turn, setTurn] = useState(0);
  const [trxnId, setTrxnId] = useState(0);
  const [rebate, setRebate] = useState(0);
  const [disable, setDisable] = useState(false);
  const progressBar = ((+wdrlBal / bal || 0) * 100).toString();
  const Wallet = GetWallet();

  useEffect(() => {
    updateAmt();
    getTurnover();
  }, [bal]);

  function updateAmt() {
    api.GetBalance({
      success: (res) => {
        setBal(res.data.currbal);
      },
    });
  }

  function getTurnover() {
    api.UserTurnover({});
  }

  const formatComm = (amt) => {
    const n = format.chopDecimal(amt, 0);

    return format.formatCurrency(
      n.length > 4
        ? format.chopDecimal(amt, 0)
        : n.length > 3
        ? format.chopDecimal(amt, 2)
        : format.chopDecimal(amt, 4)
    );
  };

  const printOutput = (text, amount) => {
    const n = format.chopDecimal(amount, 0);

    let formattedAmount = format.formatCurrency(
      n.length > 4
        ? format.chopDecimal(amount, 0)
        : n.length > 3
        ? format.chopDecimal(amount, 2)
        : format.chopDecimal(amount, 4)
    );

    let translateText = trans(text);
    return `VIP ${formattedAmount}`;
  };

  return (
    <div className={`member-fund-container`}>
      <div className={`member-fund-card-background ${containerClassName}`}>
        <img
          src={iconImg ? iconImg : Wallet.moneyIcon1}
          alt="money"
          className="fundCard-money-image"
        />
        <div className={`member-fund-card-content`}>
          <div className="member-fund-row1">
            <div className={`member-fund-name ${nameClassName}`}>
              {wallet.acctname
                ? wallet.acctname
                : trans("withdraw.label.YourWal")}
            </div>
            {/* <ButtonComponent_v2
          btnClassName="member-fund-rebate-btn"
          text={printOutput('general.label.totalRebateEarn', rebate)}
          onClick={() => {
            navigate('/transaction');
          }}
        /> */}
          </div>
          <div className="member-fund-row2">
            <div className={`member-fund-bal-title ${balTitleClassName}`}>
              {trans("general.label.totalBal")} ({currency})
            </div>
            <div className={`member-fund-bal-amount ${balAmountClassName}`}>
              {format.formatCommonBalance(bal, isMobile)}
            </div>
            <div
              className={`member-fund-withdrawable-bal ${withdrawBalClassName}`}
            >
              {" "}
              {trans("fundTransfer.card.wdrlBal")} ({currency}):{`  `}{" "}
              {format.formatWithdrawableBalance(wdrlBal)}
            </div>
          </div>
        </div>
      </div>

      <div className="member-fund-row3">
        <ButtonComponent_v2
          btnClassName={`member-topup-btn ${btnTopupClassName} ${
            disable && "member-topup-btn-disabled"
          }`}
          text={trans("general.btn.topup")}
          onClick={() => {
            if (topupCallback) {
              topupCallback();
            }
            // setDisable(true);
            if (!disable) {
              navigate("/autoDeposit");
            }

            // navigate('/deposit', { state: { bal, wdrlBal, progressBar, rebate, turn, },});
          }}
        />
        <ButtonComponent_v2
          btnClassName={`member-withdraw-btn ${btnWithdrawClassName} ${
            disable && "member-withdraw-btn-disabled"
          }`}
          text={trans("general.btn.withdraw")}
          onClick={() => {
            if (withdrawCallback) {
              withdrawCallback();
            }
            setDisable(true);
            if (!disable) {
              api.UserBankList({
                navigate: () => {
                  navigate("/withdraw");
                },
                noBankNavigate: () => {
                  setDisable(false);
                },
                success: () => {
                  setDisable(false);
                },
                error: () => {
                  setDisable(false);
                },
              });
            }
          }}
        />
      </div>
    </div>
  );
}
