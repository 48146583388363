import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./Mission.scss";
import "../../common/components/listing/FlatList.scss";
import { GetCommon } from "../../resources/image/Image";
import TopNav from "../modal/TopNav";
import Info from "../../common/components/root/info/Info";
import CPListTab, { Tab } from "../../common/components/tab/CPListTab";
import { useSelector } from "react-redux";
import api from "../../common/api";
import CPListing from "../../common/components/listing/CPListing";
import CPProgressBar from "../../common/components/progressBar/CPProgressBar";
import CPButton from "../../common/components/button/CPButton";
import FlatList from "../../common/components/listing/FlatList";
import { cronParser } from "../../common/utils/helper";
import { toLocalString } from "../../common/utils/format";
import Dialog from "../../common/components/root/dialog/Dialog";
import Modal from "../../common/components/modal/Modal";
import { useNav as useNavigate } from "../../common/utils/helper";
import { isEqual } from "lodash";
import { store } from "../../common/redux/Store";

export default function Mission({ title }) {
  const missionLocalKey = "mList";
  const Common = GetCommon();
  const { currency } = useSelector(({ company }) => company);
  const { wallet } = useSelector(({ user }) => user);
  const navigate = useNavigate();
  const { t: trans } = useTranslation();

  const [mission, setMission] = useState([]);
  const [record, setRecord] = useState([]);
  const [item, setItem] = useState({});
  const [pending, setPending] = useState(0);
  const [data, setData] = useState([]);
  const [type, setType] = useState("all");
  const [bal, setBal] = useState(wallet.currbal);
  const [isOpen, setIsOpen] = useState(false);
  const [gameStatus, setGameStatus] = useState(trans("game.loading"));
  const { isLogin } = store.getState().user;

  const filterData = [
    {
      title: trans("mission.filter.inProgress"),
      type: type === "progress",
      onClick: () => {
        setType("progress");
        filterList("in progress");
      },
    },
    {
      title: trans("mission.filter.completed"),
      type: type === "completed",
      onClick: () => {
        setType("completed");
        filterList("completed");
      },
    },
    {
      title: trans("mission.filter.record"),
      type: type === "record",
      onClick: () => {
        setType("record");
        filterList("record");
      },
    },
  ];

  useEffect(() => {
    //true mean no localdata, default value is falsy mean rendered localdata
    const renderLocal = renderMissionUI(null, true);

    missionRec(renderLocal ? true : false);
  }, []);

  function renderMissionUI(response, renderLocal = false) {
    let res = response;
    const mListLocal = JSON.parse(localStorage.getItem(missionLocalKey));

    //if api res same with local dont re-render
    if (mListLocal && isEqual(mListLocal, res) && !renderLocal) {
      return;
    }

    //if local existed render local first, else exit function since no data to render
    if (renderLocal) {
      if (mListLocal) {
        res = mListLocal;
      } else {
        // return true for indicate whether it render local,
        // default return falsy value mean success,
        // if true mean unsuccess.
        return true;
      }
    }

    localStorage.setItem(missionLocalKey, JSON.stringify(res));

    const data = res.data;

    if (res.noData) {
      setGameStatus(trans("game.noGame"));
      return;
    }

    let allMission = data.all_mission
      .map((x) => {
        const m = data.user_progress.find((y) => y.missionId === x.missionId);
        return {
          ...x,
          claimed: m ? m.claimed : 0,
          progress: m ? m.progress : 0,
          claimedTime: m ? m.claimedTime : "",
          remark: m ? (m.remark ? m.remark : "") : "",
        };
      })
      .filter((e) => !e.claimed || (e.claimed && e.display === "1"));

    let today_record = data.all_mission.map((x) => {
      const m = data.user_progress.find((y) => y.missionId === x.missionId);
      return {
        ...x,
        claimed: m ? m.claimed : 0,
        progress: m ? m.progress : 0,
        claimedTime: m ? m.claimedTime : "",
        reward: m ? (m.claimed ? m.cost : x.reward) : x.reward,
        remark: m ? (m.remark ? m.remark : "") : "",
      };
    });

    // past record + today record
    const allRecord = today_record
      .filter((e) => e.claimed)
      .concat(data.record_list)
      .sort((a, b) => {
        return new Date(b.claimedTime) - new Date(a.claimedTime);
      });

    const claimed = allRecord;

    const fM = allMission
      ? allMission
      : allMission.filter((item) => item.missionId !== "APP_03");

    const pendingList = fM.filter((e) => e.progress < e.target);
    setMission(fM);

    setPending(pendingList.length);

    filterList(type, fM);

    setRecord(claimed);
  }

  async function missionRec(spinner) {
    api.MissionList({
      spinner: false,
      success: (res) => {
        renderMissionUI(res, false);
      },
      error: () => {
        setGameStatus(trans("game.noGame"));
      },
    });
  }

  const filterList = (type, res) => {
    const filter = mission; //.filter((e) => !e.claimed);

    let fil = res || filter;

    if (type === "in progress") {
      fil = fil.filter((item) => item.progress < item.target && item.progress);
    }

    if (type === "completed") {
      fil = fil.filter((item) => item.progress >= item.target && !item.claimed);
    }

    if (type === "record") {
      fil = mission.filter((item) => {
        return item.claimed;
      });
    }

    if (fil.length === 0) {
      setGameStatus(trans("game.noGame"));
    }

    setData(fil);
  };

  function Record() {
    return (
      <FlatList
        containerStyle={"mission-flatlist"}
        getStatus={gameStatus}
        data={record}
        horizontal={false}
        renderItem={(item, index) => (
          <CPListing
            key={index}
            listStyle={"mission-record-container"}
            leftItem={
              <img
                alt="mission-record-icon"
                src={Common.topup}
                className="mission-record-icon"
              />
            }
            topLeftItem={item.title}
            topRightItem={`+ ${currency} ${item.reward.toFixed(2)}`}
            bottomLeftItem={
              <div className="mission-record-item-left">{item.claimedTime}</div>
            }
            bottomRightItem={
              <div className={`mission-record-status-container`}>
                <div className={`mission-record-status-icon`} />
                {trans("general.list.success")}
              </div>
            }
          />
        )}
      />
    );
  }

  function Mission() {
    if (data.length === 0) {
      return (
        <div className="flatList-container">
          <div className="noData">{gameStatus}</div>
        </div>
      );
    } else {
      return data.map((item, index) => {
        function More() {
          const desc = item.desc;
          const max = window.innerWidth <= 430 ? 30 : 39;

          return (
            <div id={"desc"} className="mission-content-desc-container">
              {desc.length > max ? (
                <>
                  {desc.substring(0, max)} ...{` `}
                  <span className="mission-more">
                    {trans("mission.moreInfo")}
                  </span>
                </>
              ) : (
                desc
              )}
            </div>
          );
        }

        function LabelBtn() {
          var countdown = item.cron
            ? cronParser(item.cron)
            : { hours: 0, minutes: 0, seconds: 0 };

          const animEl = useRef(null);
          const [animIndex, setAnimIndex] = useState(0);
          const [time, setTime] = useState(countdown);

          const hours = time.hours < 1 ? "" : `${time.hours}:`;
          const minutes =
            time.minutes < 0 ? "" : `${toLocalString(time.minutes)}:`;
          const seconds = time.seconds < 0 ? "0" : toLocalString(time.seconds);

          const disable = item.progress >= item.target;
          let label;
          let style;

          useEffect(() => {
            if (item.cron) {
              animEl.current.addEventListener("animationiteration", () => {
                setAnimIndex((currentIndex) => {
                  if (currentIndex + 1 < animArr.length) {
                    return currentIndex + 1;
                  } else {
                    return 0;
                  }
                });
              });
            }
          }, []);

          useEffect(() => {
            if (item.cron) {
              let timerId;

              if (time.minutes < 0) {
                if (timerId) {
                  clearInterval(timerId);
                }
                return;
              }
              timerId = setInterval(() => {
                if (time.seconds <= 0) {
                  if (time.minutes <= 0) {
                    if (time.hours <= 0) {
                      setTime({ ...time, minutes: -1, second: -1 });
                    } else {
                      setTime({
                        ...time,
                        hours: time.hours - 1,
                        minutes: 59,
                        seconds: 59,
                      });
                    }
                  } else {
                    setTime({
                      ...time,
                      minutes: time.minutes - 1,
                      seconds: 59,
                    });
                  }
                } else setTime({ ...time, seconds: time.seconds - 1 });
              }, 1000);

              return () => clearInterval(timerId);
            }
          }, [time]);

          if (!item.progress) {
            label = trans("mission.learnMore");
            style = "mission-no-progress";
          } else if (!disable) {
            label = trans("mission.inProgress");
            style = "mission-in-progress";
          } else if (item.claimed) {
            label = trans("mission.claimed");
            style = "mission-claimed";
          } else {
            label = trans("mission.claimNow");
            style = "mission-claimed";
          }

          const animArr = [
            { text: label },
            { text: `${hours}${minutes}${seconds}` },
          ];

          return (
            <button className={`mission-btn ${style}`}>
              {item.cron ? (
                <div
                  className={`mission-btn-txt ${style} mission-label`}
                  style={{ background: "none" }}
                  ref={animEl}
                >
                  {animArr[animIndex].text}
                </div>
              ) : (
                <div
                  className={`mission-btn-txt ${style}`}
                  style={{ background: "none" }}
                >
                  {label}
                </div>
              )}
            </button>
          );
        }
        return (
          <div
            className={`mission-listing-style ${
              item.cover ? "mission-cover-container" : "mission-list-container"
            }`}
            onClick={() => {
              setItem(item);
              setTimeout(() => {
                setIsOpen(true);
              }, 200);
            }}
            key={index}
          >
            {item.cover ? (
              <>
                <img
                  alt="mission-cover"
                  src={item.cover}
                  className="mission-cover"
                />
                <div className="mission-line" />
              </>
            ) : null}
            <div
              className="mission-listing-content-container"
              style={{ height: item.cover ? "50%" : "100%" }}
            >
              <div className="mission-content-style">
                <div className="mission-content-title-container">
                  {item.title}
                </div>
                <More />
              </div>
              <div className="mission-reward-container">
                <div className="mission-reward-style">
                  {item.reward === "0" ? null : (
                    <img
                      src={Common.coin}
                      alt={"mission-coin"}
                      className="mission-coin"
                    />
                  )}

                  {item.reward.includes("~") || item.reward === "0"
                    ? null
                    : "+ "}
                  {item.reward === "0" || undefined
                    ? null
                    : `${item.reward} ${currency}`}
                </div>
                <LabelBtn />
              </div>
            </div>
            {!item.progress ? null : (
              <div className="mission-progress">
                <CPProgressBar
                  container="mission-progress-style"
                  width={`${((item.progress / item.target) * 100).toString()}%`}
                />
              </div>
            )}
          </div>
        );
      });
    }
  }

  function updateAmt() {
    api.GetBalance({
      success: (res) => {
        setBal(res.data.currbal);
      },
    });
  }

  const buttonTxt = item.button;
  const disable = item.progress >= item.target;

  let label;
  let style;
  let textStyle;

  if (!item.progress) {
    label = buttonTxt;
    style = "mission-popup-btn";
    textStyle = "mission-popup-btn-txt";
  } else if (!disable) {
    label = trans("mission.go");
    style = "mission-in-progress";
    textStyle = "mission-popup-in-progress-txt ";
  } else if (item.claimed) {
    label = trans("mission.claimed");
    style = "mission-claimed";
    textStyle = "mission-popup-claimed-txt";
  } else {
    label = trans("mission.claimNow");
    style = "mission-claimed";
    textStyle = "mission-popup-claimNow-txt";
  }

  function PopupBtn() {
    return (
      <CPButton
        btnBg={`${style}`}
        textStyle={`mission-popup-txt ${textStyle}`}
        title={label}
        onClick={() => {
          document.body.style.overflow = "scroll";

          if (item.progress >= item.target) {
            api.MissionClaim({
              mission: item,
              success: (res) => {
                Dialog.show({
                  show: true,
                  msg: trans("dialog.msg.successful"),
                  content: (
                    <div className="mission-reward-dialog">
                      + {res.data.reward} {currency}
                    </div>
                  ),
                  type: "success",
                  btnAct: () => {
                    setIsOpen(false);
                    updateAmt();
                    setType("record");
                    filterList("record");
                    missionRec(true);
                  },
                });
              },
              error: () => {},
            });
          } else {
            if (item.cta === "Topup") {
              navigate("/fund", {
                replace: true,
                state: {
                  back: "/mission",
                },
              });
            } else if (item.cta === "Downline") {
              navigate("/affiliate", {
                replace: true,
                state: {
                  back: "/mission",
                },
              });
            } else if (item.cta === "LearnMore") {
              navigate("/learnMore", {
                replace: true,
                state: {
                  back: "/mission",
                },
              });
            } else if (item.cta === "Main") {
              navigate("/");
            }
          }
        }}
      />
    );
  }

  return (
    <div className="mission-mainContainer container-padding">
      {/* <TopNav
        title={title}
        rightBtn={() => Info.show({type: 'mission'})}
        // leftBtn={() => navigate('/')}
      /> */}
      <Modal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="mission-popup-style">
          <div className="mission-top-bg">
            {item.cover ? (
              <img
                alt={"mission-pop-cover"}
                src={item.cover}
                className="mission-popup-cover"
              />
            ) : null}
            <div className="mission-popup-title">{item.title}</div>
            {item.reward === "0" || undefined ? null : (
              <div className="missionReward-btn-container">
                <CPButton
                  disabled
                  icon={
                    item.reward?.includes("~")
                      ? Common.missionReward
                      : Common.coin
                  }
                  btnStyle={"missionReward-btn"}
                  iconStyle={
                    item.reward?.includes("~")
                      ? "missionReward-icon"
                      : "mission-coin"
                  }
                  title={`+ ${item.reward} ${currency}`}
                  textStyle={"missionReward-txt"}
                />
              </div>
            )}
          </div>
          <div className="mission-popup-bottom-container">
            <div className="mission-popup-content">
              <div className="mission-activity-container">
                <div className="mission-popup-label-style">
                  <div
                    className="mission-popup-label"
                    style={{ marginRight: 5 }}
                  >
                    {trans("mission.expiry")}
                  </div>
                  <div className="mission-popup-label-item">
                    {item.expireDate}
                  </div>
                </div>
                <div className="mission-popup-label-style">
                  <div
                    className="mission-popup-label"
                    style={{ marginRight: 5 }}
                  >
                    {trans("mission.status")}
                  </div>
                  <div className="mission-popup-label-item">
                    {trans("mission.active")}
                  </div>
                </div>
                <div className="mission-popup-label-style">
                  <div
                    className="mission-popup-label"
                    style={{ marginRight: 5 }}
                  >
                    {trans("mission.requirement")}
                  </div>
                  <div
                    className="mission-popup-label-item"
                    dangerouslySetInnerHTML={{ __html: item.requirement }}
                  />
                </div>
                <div className="mission-popup-label-style">
                  <div
                    className="mission-popup-label"
                    style={{ marginRight: 5 }}
                  >
                    {trans("mission.turnover")}
                  </div>
                  <div className="mission-popup-label-item">
                    {item.turnover}
                  </div>
                </div>
                {item.progress ? (
                  <CPProgressBar
                    container={"mission-popup-progress"}
                    width={`${(
                      (item.progress / item.target) *
                      100
                    ).toString()}%`}
                  />
                ) : null}
                {item.type !== "Info" ? (
                  <div className="mission-popup-label-style">
                    <div
                      className="mission-popup-label"
                      style={{ marginRight: 5 }}
                    >
                      {trans("mission.progress")}
                    </div>
                    <div className="mission-popup-label-item">
                      {item.progress}/{item.target}
                    </div>
                  </div>
                ) : null}
              </div>
              <div
                className="mission-popup-info"
                dangerouslySetInnerHTML={{ __html: item.desc }}
              />
            </div>
            <div className="mission-popup-gradient">
              <PopupBtn />
            </div>
          </div>
          <div
            className="mission-close-container"
            onClick={() => setIsOpen(false)}
          >
            <img src={Common.remove} alt="remove" />
          </div>
        </div>

        <div
          className="mission-cancel-btn"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {trans("general.btn.cancel")}
        </div>
      </Modal>
      <div className="mission-style">
        <div className="mission-sticky-header">
          {isLogin ? (
            <div className="mission-status-container">
              <div className="mission-status-status-style">
                <div className="mission-status-txt">
                  {trans("mission.pending")}
                </div>
                <div className="mission-status">
                  <div className="mission-status-num">{pending}</div>
                </div>
              </div>
              <div className="mission-amt-container">
                <div
                  className="mission-amt-style"
                  onClick={() => navigate("/fund", {})}
                >
                  <div className="mission-amt-txt">
                    {trans("general.label.totalBal")} ({currency})
                  </div>
                  <div className="mission-amt-amt-txt">{bal}</div>
                </div>
                <div
                  className="mission-amt-reload-container"
                  onClick={() => {
                    updateAmt();
                  }}
                >
                  <img
                    alt="mission-amt-reload-icon"
                    src={Common.reload}
                    className="mission-amt-reload-icon"
                  />
                </div>
              </div>
            </div>
          ) : null}
          <CPListTab
            container={"mission-tab"}
            calender={false}
            data={filterData}
            firstTabType={type === "all"}
            firstTabOnClick={() => {
              setType("all");
              filterList("all");
            }}
            renderItem={(item, index) => {
              return (
                <Tab
                  key={index}
                  title={item.title}
                  type={item.type}
                  onClick={item.onClick}
                />
              );
            }}
          />
        </div>

        <div className="mission-listing-container">
          {type === "record" ? <Record /> : <Mission />}
        </div>
      </div>
    </div>
  );
}
