import React from 'react';
import './LoginOrSeparator.scss';
import {useTranslation} from 'react-i18next';

export default function LoginOrSeparator() {
  const {t: trans} = useTranslation();

  return (
    <div className="loginOrSeparator-container">
      {/* <div className="loginOrSeparator-separator" />
      <div className="loginOrSeparator-separator-txt">{trans('signIn.or')}</div>
      <div className="loginOrSeparator-separator" /> */}
    </div>
  );
}
