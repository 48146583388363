import { t as trans } from "i18next";

/**
 * Rounds the value to given number of decimal point correctly.
 * @param {number} n Float value to be rounded.
 * @param {number} decimal The number of decimal point to round to.
 * @param {boolean} [toUp=false] The optional value to round up or round down the decimal value of the number. Default is set to false.
 * @param {boolean} [isNum=true] The optional value to return the result as number or string. Default is set to true.
 */
export function roundTo(n, decimal, toUp, isNum) {
  // Reset digit to 0 if it is not valid digit
  if (/\D+/g.test(decimal)) {
    decimal = 0;
  }

  // Reset toUp to false if it is not valid (true/false/1/0)
  if (!/^(true|false|1|0)$/g.test(toUp)) {
    toUp = false;
  }

  // Reset isNum to true if it is not valid (true/false/1/0)
  if (!/^(true|false|1|0)$/g.test(isNum)) {
    isNum = true;
  }

  var multiplicator = Math.pow(10, decimal);
  n = parseFloat((n * multiplicator).toFixed(11));
  var test = (toUp ? Math.round(n) : Math.floor(n)) / multiplicator;
  const fix = test.toFixed(decimal);

  return isNum ? +fix : fix;
}

/**
 * Chop out additional decimals that is more than given decimal point
 * @param {number} n Float value to be chopped
 * @param {number} point The number of decimal point to keep
 */
export function chopDecimal(n, point) {
  if (/\D+/g.test(point)) {
    point = 0;
  }

  const str = ("" + n).split(".");
  const decimal = str[1] ? str[1].substring(0, point) : "";

  return str[0] + (decimal ? "." + decimal : "");
}

/**
 * Convert the value to currency  (Ex. 1000 -> 1,000.00)
 * @param {number} n Float value to be converted into currency
 */
export function formatCurrency(n) {
  // return `${(+n).toFixed(2)}`.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  var str = n.toString().split(".");

  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  if (str[1] && str[1].length >= 5) {
    str[1] = str[1].replace(/(\d{3})/g, "$1 ");
  }

  return str.join(".");
}

/**
 * Capitalise the first character of the string
 * @param {string} abc String value to be formatted
 */
export function firstCap(abc) {
  return abc.replace(/^./, (str) => str.toUpperCase());
}

/*
 * "yourStringHere" -> "Your String Here"
 * "AnotherStringHere" -> "Another String Here"
 * "someones_string" -> "Someones String"
 * "Another-String-Here" -> "Another String Here"
 * "myAWESOMEString" -> "My AWESOME String"
 */
export function firstCapNSpacing(abc) {
  return abc
    .replace(/(_|-)/g, " ")
    .trim()
    .replace(/\w\S*/g, function (str) {
      return str.charAt(0).toUpperCase() + str.substr(1);
    })
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
}

export function removeSpace(abc) {
  return abc.replace(/\s/g, "");
}

export function millisecondToTime(time, countdown = 180) {
  var createTime = time;

  var currentTime = Math.floor(new Date().getTime() / 1000);
  var diffTime = currentTime - createTime;

  if (diffTime < countdown) {
    const remain = countdown - diffTime;

    // return {mins: Math.floor(remain / 60), secs: remain % 60};
    return remain;
  }
}

export function toLocalString(number) {
  return number.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

export const getMonth = (item) => {
  switch (item) {
    case 0:
      return trans("general.months.jan");
    case 1:
      return trans("general.months.feb");
    case 2:
      return trans("general.months.mar");
    case 3:
      return trans("general.months.apr");
    case 4:
      return trans("general.months.may");
    case 5:
      return trans("general.months.jun");
    case 6:
      return trans("general.months.jul");
    case 7:
      return trans("general.months.aug");
    case 8:
      return trans("general.months.sep");
    case 9:
      return trans("general.months.oct");
    case 10:
      return trans("general.months.nov");
    case 11:
      return trans("general.months.dec");

    default:
      return "";
  }
};

export const formatWithdrawableBalance = (amt) => {
  return formatCurrency(chopDecimal(amt, 0));
};

export const formatTotalBalance = (amt) => {
  return amt.toFixed(2);
};

export const formatCommonBalance = (amt, isMobile) => {
  const n = chopDecimal(amt, 2);

  return formatCurrency(
    n.length <= (isMobile ? 9 : 10) ? formatCurrency(n) : chopDecimal(amt, 0)
  );
};

//#region ---------- Internal ----------

//#endregion
