import React, { useRef, useState } from "react";
import "./RegisterBank.scss";
import { useTranslation } from "react-i18next";
import { GetLogin, GetTextField } from "../../resources/image/Image";
import ButtonComponent_v2 from "../../common/components/button/ButtonComponent_v2";
import FormComponent from "../../common/components/Form/FormComponent";
import PasswordTextField from "../../common/components/TextField/PasswordTextField";
import LoginCommonTf from "../../common/components/TextField/LoginCommonTf";
import BankDropDownBorderTextField from "../../common/components/TextField/BankDropDownBorderTextField";
import { clientInfo } from "../../resources";
import RegisterStepImgComponent from "../../common/components/Register/RegisterStepImgComponent";
import api from "../../common/api";
import { Navigate, useLocation } from "react-router-dom";
import { clearReferral, useNav } from "../../common/utils/helper";
import axios from "axios";
import {
  MsgModalObject,
  useMsgModal,
} from "../../common/context/MsgModalContext";
import ErrMsgModal from "../../common/components/modal/ErrMsgModal";
import { SignUpAndLogin } from "../../common/utils/loginHelper";
import { useSelector } from "react-redux";

export default function RegisterBank() {
  const location = useLocation();
  const { userData } = location?.state || {};

  if (!userData) {
    return <Navigate to="/" />;
  }
  return <RegisterBankContent />;
}

function RegisterBankContent() {
  const navigate = useNav();
  const { pushMsgModal } = useMsgModal();
  const location = useLocation();
  const { userData } = location?.state || {};
  const { t: trans } = useTranslation();
  const loginImg = GetLogin();
  const tfImg = GetTextField();
  const containerRef = useRef(null);

  const nameTfName = "name";
  const bankTfName = "bank";
  const accNoTfName = "accNo";
  const pwTfName = "password";
  const confirmPwTfName = "confirmPassword";
  const formRef = useRef(null);
  const submitBtnRef = useRef(null);
  const [formData, setFormData] = useState({});
  const [verifyingAcc, setVerifyingAcc] = useState(false);
  const enablePassword = clientInfo.register.enableRegisterBankPasswordSecurity;
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [verifyBankCT, setVerifyBankCT] = useState(null);
  const { regSkipOtp } = useSelector(({ company }) => company);

  const handleFieldChange = (e) => {
    const { name, value } = e;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const updateFormData = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleContinueClicked = async () => {
    setIsDisableBtn(true);
    const isValid = await formRef.current.ValidateValue({});
    console.log(formData);
    if (isValid) {
      const registerPw = userData.password ?? formData[pwTfName];
      const mobile = userData.mobile;
      const referral = userData.referral;
      const lineUuid = userData.lineUuid;
      const bank = {
        bankCode: formData[bankTfName].bankCode,
        accNum: formData[accNoTfName],
      };

      await SignUpAndLogin(
        navigate,
        mobile,
        referral,
        registerPw,
        lineUuid,
        bank
      );
    }

    setIsDisableBtn(false);
  };

  const cancelVerifyBankAPI = (autoSetVerifying) => {
    if (verifyBankCT) {
      verifyBankCT.cancel();
      if (autoSetVerifying) {
        setVerifyingAcc(false);
      }
    }
  };

  const GetBankAccName = async (bank, accNum, manualSetErr, from) => {
    let isValid = true;
    let validationErrMsg = "";
    if (!bank) {
      const bankTf = formRef.current.getTextFieldRefByName(bankTfName)?.current;
      const requiredMsg = "general.errMsg.fieldRequired";
      if (bankTf) {
        bankTf.setErrMsg(requiredMsg);
      }
      //if from same fields then need return the errMsg
      if (from == bankTfName) {
        validationErrMsg = requiredMsg;
      }
      isValid = false;
    }

    const accNumTf =
      formRef.current.getTextFieldRefByName(accNoTfName)?.current;
    if (!accNum) {
      const requiredMsg = "general.errMsg.fieldRequired";
      if (accNumTf) {
        accNumTf.setErrMsg(requiredMsg);
      }
      //if from same fields then need return the errMsg
      if (from == accNoTfName) {
        validationErrMsg = requiredMsg;
      }
      isValid = false;
    }

    const bankCode = bank?.bankCode ?? null;
    if (bankCode && accNum?.length > 0) {
      let formatMsg = "";
      if (bankCode == "GHB" || bankCode == "GSB") {
        if (accNum?.length != 12) {
          formatMsg = "general.errMsg.invalidAccNum";
        }
      } else if (bankCode == "BAAC") {
        if (accNum?.length != 10 && accNum?.length != 12) {
          formatMsg = "general.errMsg.invalidAccNum";
        }
      } else {
        if (accNum?.length != 10) {
          formatMsg = "general.errMsg.invalidAccNum";
        }
      }

      if (formatMsg != "") {
        isValid = false;
        if (from == accNoTfName) validationErrMsg = formatMsg;
        accNumTf?.setErrMsg(formatMsg);
      }
    }

    if (!isValid) {
      cancelVerifyBankAPI(true);
      return validationErrMsg;
    }
    cancelVerifyBankAPI(false);

    const source = axios.CancelToken.source();
    setVerifyBankCT(source);
    setVerifyingAcc(true);
    const result = await new Promise((resolve) => {
      api.VerifyBank({
        bankCode: bank.bankCode,
        accNum: accNum,
        cancelToken: source.token,
        success: (data) => {
          const finalData = data.data;
          const accName = finalData.acctname
            ? finalData.acctname
            : finalData.thaiAcctName;

          resolve({ value: accName });
        },
        error: (err) => {
          console.log(err);
          resolve({ isErr: true, ...err });
        },
        cancelled: () => {
          resolve({ isCancelled: true });
        },
      });
    });

    //if canncel just ask tf to remain the previous errMsg.
    if (result.isCancelled) return { isRemain: true };

    setVerifyingAcc(false);

    const accNumValue = result.value;
    let errMsg = "";

    if (accNumValue) {
      updateFormData(nameTfName, accNumValue);
    } else {
      updateFormData(nameTfName, "");
      //2036 repesent bank used by other users
      if (result?.errcode == "2036") {
        errMsg = "general.errMsg.bankExists";
      } else {
        errMsg = "general.errMsg.invalidAccNum";
      }
    }

    if (manualSetErr) {
      if (accNumTf) {
        accNumTf.setErrMsg(errMsg);
      }
    } else {
      console.log(`auto set err msg ${errMsg}`);
      return errMsg;
    }
  };

  return (
    <div className="registerBank-container container-padding">
      <div className="registerBank-style" ref={containerRef}>
        <div className="registerBank-title-section">
          <div className="registerBank-title">
            {trans("registerBank.title")}
          </div>
          {regSkipOtp ? (
            <RegisterStepImgComponent step={2} />
          ) : (
            <RegisterStepImgComponent step={3} />
          )}
        </div>
        <FormComponent
          className="registerBank-form-container registerBank-form-layout"
          ref={formRef}
          frameRef={containerRef}
          submitBtnRef={submitBtnRef}
        >
          <BankDropDownBorderTextField
            isFormChild={true}
            titleClassName="dropDownTextField-modal-title"
            separatorClassName="dropDownTextField-modal-separator"
            inputClassName="baseTextField-modal-input"
            placeholderClassName="baseTextField-modal-placeholder"
            placeholder={trans("general.placeholder.selectBank")}
            name={bankTfName}
            disabled={isDisableBtn}
            isRequired={true}
            value={formData[bankTfName] || null}
            onChange={handleFieldChange}
            isRealTimeValidation={true}
            disabledFromFormValidation={true}
            validation={async (value) => {
              const accNum = formData[accNoTfName];
              console.log(`bank form validation`, accNum);
              return GetBankAccName(value, accNum, true, bankTfName);
            }}
          />

          <LoginCommonTf
            isLoading={verifyingAcc}
            isFormChild={true}
            icon={tfImg.bank}
            name={accNoTfName}
            placeholder={trans("general.placeholder.enterAcctNo")}
            value={formData[accNoTfName] || ""}
            onChange={(e) => {
              handleFieldChange(e);
              if (e.value == "") {
                cancelVerifyBankAPI(true);
              }
            }}
            disabled={isDisableBtn}
            isRequired={true}
            showErrImg={true}
            isRealTimeValidation={true}
            inputMode="numeric"
            allowFirstZero={true}
            validation={async (value) => {
              const selectedBank = formData[bankTfName];
              console.log(`bank acc num form validation `, selectedBank);
              return GetBankAccName(selectedBank, value, false, accNoTfName);
              // setVerifyingAcc(true);
              // const result = await new Promise((resolve) => {
              //   api.VerifyBank({
              //     bankCode: selectedBank.bankCode,
              //     accNum: value,
              //     cancelToken: cancelTokenSource.token,
              //     success: (data) => {
              //       resolve(true);
              //     },
              //     noData: (data) => {
              //       resolve(true);
              //     },
              //     error: () => {
              //       resolve(false);
              //     },
              //   });
              // });
              // setVerifyingAcc(false);
              // if (result) {
              //   return '';
              // } else {
              //   return 'general.errMsg.invalidAccNum';
              // }
            }}
          />

          <LoginCommonTf
            isFormChild={true}
            icon={tfImg.user}
            name={nameTfName}
            placeholder={trans("general.placeholder.autoName")}
            value={formData[nameTfName] || ""}
            disabled={true}
            isLoading={verifyingAcc}
          />

          {enablePassword && (
            <div
              data-includedformchild={true}
              className="registerBank-form-layout"
            >
              <div className="registerBank-separator-container">
                <div className="registerBank-separator" />
              </div>

              <PasswordTextField
                isFormChild={true}
                name={pwTfName}
                placeholder={trans("general.placeholder.password")}
                value={formData[pwTfName] || ""}
                onChange={(e) => {
                  const confirmTf =
                    formRef.current.getTextFieldRefByName(
                      confirmPwTfName
                    )?.current;

                  if (confirmTf && confirmTf.isRealTimeValidation) {
                    const confirmPwValue = formData[confirmPwTfName] ?? "";
                    const pwValue = e.value;
                    if (confirmPwValue != "") {
                      if (confirmPwValue != pwValue) {
                        confirmTf.setErrMsg("general.errMsg.pwNotMatch");
                      } else {
                        confirmTf.setErrMsg("");
                      }
                    }
                  }
                  handleFieldChange(e);
                }}
                isRequired={true}
                enableHideShow={true}
              />
              <PasswordTextField
                isFormChild={true}
                name={confirmPwTfName}
                placeholder={trans("forgotPass.label.confirmPass")}
                value={formData[confirmPwTfName] || ""}
                onChange={handleFieldChange}
                isRequired={true}
                enableHideShow={true}
                validation={(value) => {
                  if (value != formData[pwTfName] ?? "") {
                    return "general.errMsg.pwNotMatch";
                  } else {
                    return "";
                  }
                }}
              />
            </div>
          )}
        </FormComponent>

        <ButtonComponent_v2
          ref={submitBtnRef}
          text={trans("button.continue")}
          btnClassName={"btnV2-container-row-center-p17-r10-mainshadow"}
          btnTextClassName={"btnV2-btn-txt-s20-w700-cMain"}
          disable={verifyingAcc || isDisableBtn}
          onClick={handleContinueClicked}
        />
      </div>
    </div>
  );
}
