import React from 'react';
import './LoginComponent.scss';
import {useTranslation} from 'react-i18next';
import {useModal} from '../../context/ModalContext';
import {NavigateToLogin, ShowLoginDialog, useNav} from '../../utils/helper';
import {LoginWithLine} from '../../utils/loginHelper';
import {useDispatch, useSelector} from 'react-redux';
import {logOut} from '../../redux/userSlice';
import ButtonComponent_v2 from '../button/ButtonComponent_v2';
import {GetSign_In} from '../../../resources/image/Image';

const LoginComponent = () => {
  const Sign_In = GetSign_In();
  const dispatch = useDispatch();
  const {lineClientId} = useSelector(({company}) => company);
  const useModalContext = useModal();
  const {t: trans} = useTranslation();
  const navigate = useNav();

  return (
    <div className="login-component-main-container">
      <div className="login-component-login-container">
        <ButtonComponent_v2
          btnClassName={'login-component-register-btn'}
          text={trans('intropage.registerBtn')}
          onClick={() => {
            navigate('/register');
            // dispatch(logOut());
            // ShowLoginDialog(useModalContext);
          }}
        />
        <ButtonComponent_v2
          btnClassName={'login-component-login-btn'}
          text={trans('intropage.loginBtn')}
          onClick={() => {
            NavigateToLogin(navigate);
            // ShowLoginDialog(useModalContext);
          }}
        />
      </div>
      {/* <div className="login-component-separator-container">
        <div className="login-component-separator"></div>
        <div className="login-component-separator-txt">
          {trans('intropage.or')}
        </div>
        <div className="login-component-separator"></div>
      </div>
      <div className="login-component-third-login-container">
        <ButtonComponent_v2
          leftComponent={
            <div className="login-component-line-container">
              <img
                alt="line"
                src={Sign_In.signInLine1}
                className="login-component-line-btn-img"
              />
            </div>
          }
          rightComponent={<div style={{width: '20px'}}></div>}
          btnTextClassName={'login-component-line-btn-txt'}
          btnClassName="login-component-line-btn"
          text={trans('signIn.loginLine')}
          onClick={() => {
            LoginWithLine(lineClientId);
          }}
        />
      </div> */}
    </div>
  );
};

export default LoginComponent;
